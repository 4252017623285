import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">

            <div
                className="strawpoll-embed"
                id="strawpoll_e2naXzaJvyB"
                style={{
                    height: 672,
                    maxWidth: 640,
                    width: "100%",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <iframe
                    title="Bigg Boss Week 9 Voting"
                    id="strawpoll_iframe_e2naXzaJvyB"
                    src="https://strawpoll.com/embed/e2naXzaJvyB"
                    style={{
                        position: "static",
                        visibility: "visible",
                        display: "block",
                        width: "100%",
                        flexGrow: 1
                    }}
                    frameBorder={0}
                    allowFullScreen={true}
                    allowTransparency={true}
                >
                    Loading...
                </iframe>
            </div>

            <div
                className="strawpoll-embed"
                id="strawpoll_xVg71XGozyr"
                style={{
                    height: 836,
                    maxWidth: 640,
                    width: "100%",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <iframe
                    title="StrawPoll Embed"
                    id="strawpoll_iframe_xVg71XGozyr"
                    src="https://strawpoll.com/embed/xVg71XGozyr"
                    style={{
                        position: "static",
                        visibility: "visible",
                        display: "block",
                        width: "100%",
                        flexGrow: 1
                    }}
                    frameBorder={0}
                    allowFullScreen={true}
                    allowTransparency={true}
                >
                    Loading...
                </iframe>
            </div>


        </div>
    );
}

export default App;
